:root {
  --colorPrimario: #282530;
  --colorPrimarioClaro: #453F53;
  --colorSecundario: #FFC629;
  --colorGrisSecundario: #f3f3f3;
  --colorGRisTerciario: #676767;
  --colorFondoPrincipal: #f2f2f2;
  --colorFondoSecundario: #ebebeb;
  --alturaBarra: 8rem;
  --alturaBarraMobile: 5rem;
  --AltoCarrusel: 600px;
  --paddingSeccion: 4rem 0rem;
  --paddingSeccionMobile: 2.5rem 0rem;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body{
  background-color: var(--colorGrisSecundario);
}
h1 {
  font-family: "Saira Condensed" , sans-serif;
  font-weight: 900;
}
h2 {
  font-family: "Saira Condensed" , sans-serif;
  font-weight: 700;
}
h3{
  font-family: "Saira Condensed" , sans-serif;
  font-weight: 600;
}
h4, h5, h6, p {
  font-family: "Saira Condensed" , sans-serif;
  font-weight: 300;
}
strong {
  font-weight: 600;
}
a{
  font-family: "Saira Condensed" , sans-serif;
  text-decoration: none;
  color: var(--colorSecundario);
}
ul, li, option{
  font-family: "Saira Condensed" , sans-serif;
  padding-left: 0rem;
  list-style: none;
}
select, option {
  font-family: "Saira Condensed" , sans-serif;
}
button {
  font-family: "Saira Condensed" , sans-serif;
  all: unset;
}
::placeholder {
  font-family: "Saira Condensed" , sans-serif;
}
.containerPortal{
  padding: 0rem 1rem;
  @media (min-width: 576px){
    padding: 0rem 2rem;
  }
  @media (min-width: 992px){
    padding: 0rem 3rem;
  }
}
.containerTitle{
  border-bottom: solid 2px var(--colorSecundario);
  width: 100%;
  margin-bottom: 2.5rem;
}
.styleTitle{
  border-bottom: solid 4px var(--colorSecundario);
  margin: 0px;
  width: max-content;
}
.containerSubTitle{
  border-bottom: solid 2px var(--colorPrimarioClaro);
  width: 100%;
  margin: 1.5rem 0rem;
}
.styleSubTitle{
  border-bottom: solid 4px var(--colorPrimarioClaro);
  margin: 0px;
  width: max-content;
}

.titleProdut{
  background-color: var(--colorPrimario);
  color: #fff;
  border-radius: 0.5rem;
  text-align: center;
  padding: 1rem; 
  width: 100%;
  @media (min-width: 992px){
    padding: 2rem;

  }
}

/* PADDING SEPARACION DE SECCIONES */

.paddingSection{
  margin: var(--paddingSeccionMobile);
  @media (min-width: 992px){
    margin: var(--paddingSeccion);
  }
}

/* CSS DE LOGOS PDF EN COMUN */
.containerPdf{
  display: grid;
  grid-template-columns: 15% 75% 10%;
  align-items: center;
}
.containerPdf img{
  width: 70%;
}
.containerPdf p{
  margin: 0rem;
  text-align: start;
}

/* EDITH DE BOOSTRAPS */

.accordion-button:not(.collapsed){
  background-color: var(--colorPrimarioClaro);
  color: #fff;
  box-shadow:none;
  font-family: var(--fuenteSecundaria);
}
.accordion-button:not(.collapsed)::after{
  filter: brightness(0%) invert(100%);
}
.accordion-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(77, 76, 70, 0.25);
}
.list-group-item {
  border: none !important;
}
.form-control:focus {
  box-shadow: 0px 0px 3px 1px rgba(231,166,26,1) !important;
  border-color: var(--colorSecundario) !important;
}
.form-select:focus{
  box-shadow: 0px 0px 3px 1px rgba(231,166,26,1) !important;
  border-color: var(--colorSecundario) !important;
}

/* CARROUSEL */

.carouselElement {
  text-align: center;
  width: 100%;
  overflow: hidden;
}
.carouselElementContainer {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  padding-bottom: 1rem;
}
.carouselElementContainer::-webkit-scrollbar {
  display: none;   
}
.carouselElementCard {
  flex: 0 0 auto; /* Permite que cada tarjeta se ajuste automáticamente */
  width: 50%; /* Cada tarjeta ocupa el 80% del contenedor en móvil */
  scroll-snap-align: center; /* Centra cada tarjeta cuando se detiene el scroll */
  text-align: center;
  transition: transform 0.3s;
  margin-right: 0.5rem; /* Añade espaciado entre tarjetas */
}
@media (min-width: 992px) {
  .carouselElementCard {
    width: 15%; /* Ajuste para pantallas más grandes */
    margin-right: 1rem;
  }
  .carouselElementCard .card {
    width: 100% !important;
  }
  .carouselElementContainer{
    margin-bottom: 3rem;
  }
  .carouselElementContainer::-webkit-scrollbar {
    display: block;
    width: 50%;
    height: 8px; /* Ajusta la altura del scrollbar */
  }
  .carouselElementContainer::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5); /* Color del thumb del scroll */
    border-radius: 10px; /* Bordes redondeados del scroll */
  }
  .carouselElementContainer::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1); /* Color del fondo del scrollbar */
  }
}